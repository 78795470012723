import React from 'react';
import { Helmet } from 'react-helmet';

const GlobalHead = () => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta name="description" content="Dokwest29" />
    <meta name="subject" content="Dokwest29" />
    <title>Dokwest29</title>
    <meta name="keywords" content="HTML, CSS, XML, JavaScript" />
    <meta name="author" content="Dokwest29" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="robots" content="index, follow" />
    <meta name="googlebot" content="index,follow" />
    <meta name="theme-color" content="#099268" />
    <meta property="og:url" content="https://dokwest29.be" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Dokwest29" />
    <meta property="og:description" content="Dokwest29" />
    <meta property="og:locale" content="nl_BE" />
    <meta property="article:author" content="Dokwest29" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:url" content="https://dokwest29.be" />
    <meta name="twitter:title" content="Dokwest29" />
    <meta name="twitter:description" content="Dokwest29" />
    <html lang="nl" />
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700&display=swap" rel="stylesheet" />
  </Helmet>
);

export default GlobalHead;
