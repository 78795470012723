import * as React from 'react';
import StyleNormalizer from './StyleNormalizer';
import { ThemeProvider, theme } from './theme';
import GlobalHead from './GlobalHead';

const GlobalCollection = ({ children }) => {
  return (
    <ThemeProvider theme={theme.default}>
      <StyleNormalizer />
      <GlobalHead />
      {children}
    </ThemeProvider>
  );
};

export default GlobalCollection;
